import React, { useState } from 'react';
import ReCaptcha from './ReCaptcha'; // Adjust the path if necessary
import gif from "../assets/media/lawggle_character_searching_v1.gif";
import '../assets/css/Form.css';

const Form = ({ setCurrentView }) => {
    const [isVerified, setIsVerified] = useState(false);
    const [token, setToken] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    const handleToken = (token) => {
        setToken(token);
        setSubmitEnabled(!!token); // Enable submit if token exists
    };

    return (
        <div className='flex flex-col md:flex-row items-center text-center pt-16 px-4 sm:px-8 md:px-16'>
            <div className='botlogo w-1/2 items-center justify-center mb-8 md:mb-4 md'>
                <img src={gif} alt="something" />
            </div>
            <div className='pgform flex-1 px-4 sm:px-8 md:px-16'>
                <h1 className='text-2xl sm:text-3xl md:text-4xl text-gray-600 text-opacity-80 p-4'>
                    We know finding the right legal pro is difficult. We make it easy.
                </h1>
                <p className='text-base sm:text-lg text-gray-600 text-opacity-75 p-4'>
                    The algorithm matches you with the best lawyer in your area by answering a few questions
                </p>
                <p className='text-xs sm:text-sm md:text-base text-gray-600 text-opacity-75 p-4'>
                    Personal Information is to connect you to a legal professional only. You will not be contacted for any other reason
                </p>
                <form onSubmit={handleSubmit} className='pageform p-4 flex flex-col'>
                    <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-1">
                        <input className='forminput bg-gray-100 border-gray-100 text-gray-900 font-light rounded-xl hover:border hover:border-gray-300 text-sm sm:text-base md:text-lg m-2 p-3' type="text" placeholder='First Name' required />
                        <input className='forminput bg-gray-100 border-gray-100 text-gray-900 font-light rounded-xl hover:border hover:border-gray-300 text-sm sm:text-base md:text-lg m-2 p-3' type="text" placeholder='Last Name' required />
                    </div>
                    <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-1">
                        <input className='forminput bg-gray-100 border-gray-100 text-gray-900 font-light rounded-xl hover:border hover:border-gray-300 text-sm sm:text-base md:text-lg m-2 p-3' type="text" placeholder='Phone No' required />
                        <input className='forminput bg-gray-100 border-gray-100 text-gray-900 font-light rounded-xl hover:border hover:border-gray-300 text-sm sm:text-base md:text-lg m-2 p-3' type="email" placeholder='Email' required />
                    </div>
                    <div className="flex justify-center p-1">
                        <ReCaptcha
                            sitekey={"6LdNxwIqAAAAACnZ-pbIFgUqgVopUi8FKJGfoTo1"}
                            callback={handleToken}
                        />
                    </div>
                    <div className="flex justify-center p-1">
                        <button disabled={!submitEnabled} className={`${submitEnabled ? 'bg-blue-600' : 'bg-grey-600 cursor-not-allowed'} bg-blue-800 text-white w-32 rounded-lg text-sm  align-center sm:text-base md:text-lg px-3 py-2 sm:p-4 mt-3 ml-2`} type="submit">
                            Let's Go
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Form;
