import React, { useState, useEffect, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import fastWingsGif from "../../assets/media/lawgglee_character_fast wings.json"; 
import gif from "../../assets/media/lawgglee_character_head tilt_v2.json";

const ChatBot = ({ setCurrentView, handleSend, messages, input, setInput, step }) => {
  const [isFinalMessage, setIsFinalMessage] = useState(false);
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const getSuggestions = (step) => {
    switch (step) {
      case 0:
        return ['need a lawyer', 'looking for a lawyer'];
      case 1:
        return ['family', 'criminal', 'injury', 'corporate', 'business'];
      case 2:
        return ['english', 'spanish', 'french'];
      case 3:
        return ["USA", "Los Angeles", "Chicago"];
      default:
        return [];
    }
  };

  const suggestions = getSuggestions(step);

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    handleSend(suggestion);

    if (step === 3) {
      setIsFinalMessage(true);
    }
  };

  const handleSendClick = () => {
    handleSend(input);
    setInput('');

    if (step === 3) {
      setIsFinalMessage(true);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row items-center h-screen overflow-auto text-center  lg:p-2">
      <div className="flex-1">
        {isFinalMessage ? (
          <Player
            autoplay
            loop
            src={fastWingsGif}
            style={{  display: 'inline' }}
          />
        ) : (
          <Player
            autoplay
            loop
            src={gif}
            style={{  display: 'inline' }}
          />
        )}
      </div>
      <div className="flex-1 px-4 lg:px-8 min-h-max">
        <div className="border rounded-xl p-4 lg:p-8 shadow-lg">
          <div className="h-80 lg:h-96 overflow-y-auto border-gray mb-4 p-4">
            {messages.map((message, index) => (
              <div 
                key={index} 
                className="flex mb-2" 
                ref={index === messages.length - 1 ? lastMessageRef : null}
              >
                <div
                  className={`p-2 max-w-80 rounded-lg ${message.user ? 'self-end ml-auto' : 'self-start'}`}
                  style={{
                    backgroundColor: message.user ? '#1E3A8A' : '#00adee',
                    color: 'white',
                  }}
                >
                  {message.text}
                </div>
              </div>
            ))}
          </div>

          {suggestions.length > 0 && (
            <div className="suggestions mb-4 mt-4">
              Suggestions:
              {suggestions.map((suggestion, index) => (
                <button
                  key={index}
                  className="bg-gray-200 text-gray-800 text-sm px-4 py-2 m-1 rounded-full shadow-sm hover:bg-gray-300"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </button>
              ))}
            </div>
          )}

          <div className="flex items-center  p-2">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-1 border border-gray-300 hover:border-gray-500 focus:outline-none rounded-full p-3 text-sm sm:text-base md:text-lg"
              placeholder="Type a message..."
            />
            <button
              onClick={handleSendClick}
              className="text-white p-3 m-2 rounded-2xl bg-orange-500 hover:bg-orange-600 text-sm sm:text-base sm:mr-4 md:text-lg"
              type="submit"
            >
              Send
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ChatBot;
