import axios from 'axios';

const openaiService = async (prompt) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: [
          { role: 'system', content: 'You are a helpful assistant to provide information about law.' },
          { role: 'user', content: prompt }
        ],
        max_tokens: 150,
        temperature: 0.7,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer sk-proj-pTz80xZYIa0J7WI2djfMT3BlbkFJydM17Ppa2oNI6vbEPxn2`,
        },
      }
    );

    if (response.data && response.data.choices && response.data.choices.length > 0) {
      return response.data.choices[0].message.content.trim();
    } else {
      console.error('Unexpected API response structure:', response.data);
      return 'I encountered an error while processing your request.';
    }
  } catch (error) {
    console.error('Error fetching response from OpenAI:', error);
    return 'I encountered an error while processing your request.';
  }
};

export default openaiService;
