import React, { useEffect, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import searchingGif from "../../assets/media/lawglee_character_searching_v2.json"; // Import the searching animation

const Lawyers = ({ lawyers, error, userDetails }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLawyers = async () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    };

    fetchLawyers();
  }, []);

  if (error) return <div>Error: {error}</div>;

  const [typeOfFirm, address] = userDetails;

  return (
    <div style={{ fontFamily: 'Poppins, sans-serif' }} className="dataContainer flex flex-col items-center justify-center p-4 lg:p-8 ">
      <div className="dataHeading pt-16">
        <h1 className="head text-2xl lg:text-4xl text-gray-600">Your Matches are almost Ready!</h1>
      </div>
      {isLoading ? (
        <div className="loadingContainer flex flex-col items-center justify-center">
          <Player
            autoplay
            loop
            src={searchingGif}
            style={{ width: '80%', maxWidth: '380px' }}
          />
          <p className="text-xl lg:text-2xl text-gray-700 mt-8">We are searching for the best matches for you...</p>
        </div>
      ) : (
        <>
          {lawyers.length > 0 ? (
           
              <div className='grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 mt-5 justify-center mb-2 gap-4' >
              {lawyers.map((lawyer, index) => (
                  <div class="w-full max-w-sm bg-white border border-gray-200 rounded-2xl shadow dark:bg-gray-800 dark:border-gray-700 sm:mb-1 px-2 py-4" key={index}>
                  
                      <div class="flex justify-between">
                            <div className='mx-4 my-auto'>
                            <img class="w-[90px] h-[64px]  rounded-full shadow-lg" src={lawyer.photo} alt={`lawyer${index + 1}`} />
                            </div>
                            <div>
                                <h5 class="text-sm font-medium text-gray-900 dark:text-white">{lawyer.name}</h5>
                                <p className="text-gray-500 text-sm">Specialty: {lawyer.area_of_law}</p>
                                <p className="text-gray-500 text-sm">Location: {lawyer.address}</p>
                            </div>
                      </div>
                  </div>
              ))}
               </div>
         
          ) : (
            <div className="noResults text-center mt-8">
              <h2 className="text-xl lg:text-2xl text-gray-700 mb-4">No lawyers found in your area for the specified field of law.</h2>
              <p className="text-gray-600">Unfortunately, we couldn't find any lawyers in {address} specializing in {typeOfFirm}.</p>
            </div>
          )}
          <div className="dataFooter flex-1 font-serif">
            <p className="text-gray-600 px-4 lg:px-16 mt-4 text-lg lg:text-2xl text-center">
              We make sure the lawyers you're matched with are accurate and available. <br />
              Please keep an eye out for an email from us shortly with the contact information of your matches. We send your
              information to the lawyers so they can reach out to you as well. This way you can decide who is the best fit
              for your needs!
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Lawyers;
