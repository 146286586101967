import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import gif from "../assets/media/lawggle_character_slow wings_1.json";

const Index = ({ setCurrentView }) => (
  <div style={{ fontFamily: 'Poppins, sans-serif' }} className="intro text-center h-screen flex flex-col pt-8 px-6 py-auto items-center justify-center">
    <h1 className="text-2xl md:text-4xl sm:text-2xl font-bold text-gray-800 mb-4">We Make Finding a Lawyer Easy</h1>
    <h2 className="text-xl md:text-2xl sm:text-xl text-gray-700 mb-8">Our Free MatchBot Uses AI to Match You with the Right Lawyer for Your Case</h2>
    <button onClick={() => setCurrentView('form')} className="bg-blue-500 text-white text-lg px-6 md:px-8 py-3 md:py-4 rounded-md shadow-lg hover:bg-blue-600 transition duration-300">
      Get Started
    </button>
    <div className='pt-8'>
      <Player
        autoplay
        loop
        src={gif}
        style={{ width: '80%', maxWidth: '380px'}}
      />
    </div>
  </div>
);

export default Index;
