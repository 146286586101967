import React, { useState, useEffect } from 'react';
import './index.css'; // Ensure this imports your Tailwind CSS
import Index from './components/Index.jsx';
import Form from './components/Form.jsx';
import ChatBot from './components/chatbot/ChatBot.jsx';
import Lawyers from './components/chatbot/Lawyers.jsx';
import openaiService from './components/OpenService.jsx';
import axios from 'axios';

const App = () => {
  const [currentView, setCurrentView] = useState('index');
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [step, setStep] = useState(0); // Track the step in the conversation
  const [lawyers, setLawyers] = useState([]);
  const [error, setError] = useState(null);
  const [userInputs, setUserInputs] = useState([]);
  const [userDetails, setUserDetails] = useState([]); // Initialize as an empty array
  const [unknownCategoryDescription, setUnknownCategoryDescription] = useState(''); // Track description for unknown category

  useEffect(() => {
    if (currentView === 'chatbot') {
      // Bot's initial greeting
      const initialMessage = { text: "Hi, i'm here to help and details matter. What kind of legal pro do you need?", user: false };
      setMessages([initialMessage]);
    }
  }, [currentView]);

  useEffect(() => {
    if (currentView === 'lawyers') {
      const fetchLawyers = async () => {
        try {
          const response = await axios.get('https://testapi.albaprotein.com/api/search', {
          // const response = await axios.get('http://127.0.0.1:8000/api/search', {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Access-Control-Allow-Origin': '*'
            },
            params: {
              firm: userDetails[0],
              language: userDetails[1],
              address: userDetails[2]
            }
          });
          console.log('output', response);

          if (response.data) {
            setLawyers(response.data);
          } else {
            setError('Unexpected response structure');
          }
        } catch (err) {
          console.error('Error fetching lawyers:', err);
          if (err.response) {
            setError(`Server responded with status code ${err.response.status}: ${err.response.data.message}`);
          } else if (err.request) {
            setError('No response received from the server');
          } else {
            setError(`Error in setting up the request: ${err.message}`);
          }
        }
      };

      fetchLawyers();
    }
  }, [currentView, userDetails]);

  const handleSend = async (inputText) => {
    if (inputText.trim()) {
      const userMessage = { text: inputText, user: true };
      let newMessages = [...messages, userMessage];
      setMessages(newMessages);
      setUserInputs([...userInputs, inputText]);
      setInput('');

      try {
        const botResponse = await openaiService(inputText);
        processBotResponse(inputText, botResponse, newMessages);
      } catch (error) {
        const errorMessage = { text: "I encountered an error while processing your request.", user: false };
        newMessages = [...newMessages, errorMessage];
        setMessages(newMessages);
        console.error('Error processing bot response:', error);
      }
    }
  };

  const processBotResponse = (userInput, botResponse, newMessages) => {
    let botMessage;
    let updatedUserDetails = [...userDetails];

    switch (step) {
      case 0:
        if (userInput.toLowerCase().includes('lawyer')) {
          botMessage = { 
            text: "Great! Which type of legal professional do you need? Family, Criminal, Corporate, Business Law, or Injury?", 
            user: false 
          };
          setStep(1);
        } else {
          botMessage = { 
            text: "How can I assist you today? Are you looking to find a lawyer?", 
            user: false 
          };
        }
        break;
      case 1:
        if (['family', 'criminal', 'injury', 'corporate', 'business'].includes(userInput.toLowerCase())) {
          updatedUserDetails[0] = userInput.toLowerCase(); // Save the type of firm
          setUserDetails(updatedUserDetails);
          botMessage = { 
            text: "Understood. Which language do you prefer? English, Spanish, or French?", 
            user: false 
          };
          setStep(2);
        }
         else {
          setUnknownCategoryDescription(userInput);
          botMessage = { 
            text: "Got it! Can you describe your situation briefly?", 
            user: false 
          };
          setStep('describe');
        }
        break;
      case 'describe':
        // Use the user's description to help identify the appropriate category
        // Here you can implement logic to determine the appropriate category based on the description
        botMessage = { 
          text: `Based on your description, it sounds like you need assistance with " ${unknownCategoryDescription} ". Which language do you prefer?`, 
          user: false 
        };
        setStep(2); // Move to the next step after identifying the category
        break;
      case 2:
        if (['english', 'spanish', 'french'].includes(userInput.toLowerCase())) {
          updatedUserDetails[1] = userInput.toLowerCase(); // Save the language
          setUserDetails(updatedUserDetails);
          botMessage = { 
            text: "Okay Great! just one more thing. Where are you located?", 
            user: false 
          };
          setStep(3);
        } else {
          botMessage = { 
            text: "Please choose a valid language: English, Spanish, or French.", 
            user: false 
          };
        }
        break;
      case 3:
        updatedUserDetails[2] = userInput.toLowerCase(); // Save the address
        setUserDetails(updatedUserDetails);
        setTimeout(() => {
          setCurrentView('lawyers'); // Navigate to the Lawyers view
        }, 3000);
        botMessage = { 
          text: "Thank you! We're finding the best lawyers for you now.", 
          user: false 
        };
        break;
      default:
        botMessage = { 
          text: "I'm not sure how to help with that.", 
          user: false 
        };
        break;
    }

    setMessages([...newMessages, botMessage]);
  };

  return (
    <>
      {currentView === 'index' && <Index setCurrentView={setCurrentView} />}
      {currentView === 'form' && <Form setCurrentView={setCurrentView} />}
      {currentView === 'chatbot' && (
        <ChatBot
          setCurrentView={setCurrentView}
          handleSend={handleSend}
          messages={messages}
          input={input}
          setInput={setInput}
          step={step}
        />
      )}
      {currentView === 'lawyers' && <Lawyers lawyers={lawyers} error={error} userDetails={userDetails} />}
    </>
  );
};

export default App;
