import React, { useEffect, useRef, useState } from 'react';

const ReCaptcha = ({ sitekey, callback }) => {
    const recaptchaRef = useRef(null);
    const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

    const onRecaptchaLoad = () => {
        setIsRecaptchaLoaded(true);
    };

    useEffect(() => {
        window.onRecaptchaLoad = onRecaptchaLoad;
        if (!window.grecaptcha) {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        } else if (window.grecaptcha && window.grecaptcha.render) {
            setIsRecaptchaLoaded(true);
        }
        
        return () => {
            window.onRecaptchaLoad = null;
        };
    }, []);

    useEffect(() => {
        if (isRecaptchaLoaded) {
            window.grecaptcha.render(recaptchaRef.current, {
                sitekey: sitekey,
                callback: callback,
                type: 'image', // Make sure the type matches your reCAPTCHA type (image for v2)
            });
        }
    }, [isRecaptchaLoaded, sitekey, callback]);

    return <div ref={recaptchaRef}></div>;
};

export default ReCaptcha;
